
import {defineComponent, onMounted} from "vue";
import Card1 from "@/view/content/cards/Card1.vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "drawer-chat",
  components: {
    Card1,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Apps", "Drawer Chat");
    });
  },
});
