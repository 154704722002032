
import { defineComponent } from "vue";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "card-4",
  components: {},
  props: {
    cardClasses: String,

    avatar: String,

    online: Boolean,

    initials: String,

    color: String,

    name: String,

    position: String,

    avgEarnings: String,

    totalSales: String,
  },
  setup() {
    const openDrawer = () => {
      DrawerComponent.getInstance("kt_inbox_compose")?.toggle();
    };

    return {
      openDrawer,
    };
  },
});
